import axios from '@/utils/request'

const Api = {
	texcherList: "/api/backyard/iam/user/list",
	studentLists: "/api/studentInfo/getByNameList",
	studentAdd: "/api/studentInfo/save",
	studentPersonView: "/api/studentInfo/getById",
	studentEdit: "/api/studentInfo/updateById",
	studentFamilyView: "/api/studentFamily/getByStudentId",
	studentFamilyAdd: "/api/studentFamily/saveBatch",
	studentFamilyDelete:"/api/studentFamily/delById",
	studentEducationView: "/api/studentEducationType/getByStudentId",
	studentEducationAdd: "/api/studentEducationType/saveBatch",
	StudentEducationDelete:"/api/studentEducationType/delById",
	studentGradeGpa: "/api/studentStandardGpa/getByStudentId",
	studentGradeGpaAdd: "/api/studentStandardGpa/save",
	studentGradeTofu: "/api/studentStandardToefl/getByStudentId",
	studentGradeTofuAdd: "/api/studentStandardToefl/save",
	studentGradeTofuEdit: "/api/studentStandardToefl/updateById",
	studentGradeTofuDelete: "/api/studentStandardToefl/delById",
	studentGradeYasi: "/api/studentStandardIelts/getByStudentId",
	studentGradeYasiAdd: "/api/studentStandardIelts/save",
	studentGradeYasiEdit: "/api/studentStandardIelts/updateById",
	studentGradeYasiDelete: "/api/studentStandardIelts/delById",
	studentGradeDuolin: "/api/studentStandardDuolingo/getByStudentId",
	studentGradeDuolinAdd: "/api/studentStandardDuolingo/save",
	studentGradeDuolinEdit: "/api/studentStandardDuolingo/updateById",
	studentGradeDuolinDelete: "/api/studentStandardDuolingo/delById",
	studentGradeSat: "/api/studentStandardSat/getByStudentId",
	studentGradeSatAdd: "/api/studentStandardSat/save",
	studentGradeSatEdit: "/api/studentStandardSat/updateById",
	studentGradeSatDelete: "/api/studentStandardSat/delById",
	studentGradeSat2: "/api/studentStandardSat2/getByStudentId",
	studentGradeSat2Add: "/api/studentStandardSat2/save",
	studentGradeSat2Edit: "/api/studentStandardSat2/updateById",
	studentGradeSat2Delete: "/api/studentStandardSat2/delById",
	studentGradeSat2Subject: "/api/studentStandardSat2Extend/getByStudentId",
	studentGradeSat2SubjectAdd: "/api/studentStandardSat2Extend/save",
	studentGradeAp: "/api/studentStandardAp/getByStudentId",
	studentGradeApAdd: "/api/studentStandardAp/save",
	studentGradeApEdit: "/api/studentStandardAp/updateById",
	studentGradeApDelete: "/api/studentStandardAp/delById",
	studentGradeALevel: "/api/studentStandardAlevel/getByStudentId",
	studentGradeALevelAdd: "/api/studentStandardAlevel/save",
	studentGradeALevelEdit: "/api/studentStandardAlevel/updateById",
	studentGradeALevelDelete: "/api/studentStandardAlevel/delById",
	studentGradeALevelSubject: "/api/studentStandardAlevelExtend/getByStudentId",
	studentGradeALevelSubjectAdd: "/api/studentStandardAlevelExtend/save",
	studentGradeGre: "/api/studentStandardGre/getByStudentId",
	studentGradeIb: "/api/studentStandardIb/getByStudentId",
	studentGradeIbAdd: "/api/studentStandardIb/save",
	studentGradeIbEdit: "/api/studentStandardIb/updateById",
	studentGradeIbDelete: "/api/studentStandardIb/delById",
	studentGradeGreAdd: "/api/studentStandardGre/save",
	studentGradeGreEdit: "/api/studentStandardGre/updateById",
	studentGradeGreDelete: "/api/studentStandardGre/delById",
	studentGradeGmat: "/api/studentStandardGmat/getByStudentId",
	studentGradeGmatAdd: "/api/studentStandardGmat/save",
	studentGradeGmatEdit: "/api/studentStandardGmat/updateById",
	studentGradeGmatDelete: "/api/studentStandardGmat/delById",
	studentAward: "/api/studentAward/getByStudentId",
	studentAwardAdd: "/api/studentAward/save",
	studentAwardEdit: "/api/studentAward/updateById",
	studentAwardDelete: "/api/studentAward/delById",
	studentActivity: "/api/studentActivity/getByStudentId",
	studentActivityAdd: "/api/studentActivity/save",
	studentActivityEdit: "/api/studentActivity/updateById",
	studentActivityDelete: "/api/studentActivity/delById",
	studentFilesList:"/api/studentFiles/getByStudentId",
	studentFilesListDelete:"/api/studentFiles/delById",
	studentFilesAdd:"/api/studentFiles/save",
	studentAccountAdd:"/api/studentAccountManage/save",
	teacherUpdate:"/api/studentInfo/updateTeacherIds",
	studentAccountLists:"/api/studentAccountManage/getByStudentId",
	studentAccountUpdate:"/api/studentAccountManage/saveBatch",
}
// 老师列表
export function listTeacher(params) {
	return axios({
		method: 'get',
		url: Api.texcherList,
		params: params
	})
}

// 学生列表
export function listStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentLists,
		data: params
	})
}
// 学生文件库列表
export function listStudentFiles(params) {
	return axios({
		method: 'post',
		url: Api.studentFilesList,
		data: params
	})
}
// 学生文件库删除
export function DeletelistStudentFiles(params) {
	return axios({
		method: 'post',
		url: Api.studentFilesListDelete,
		data: params
	})
}
//跟新学生关联老师
export function updateTeacher(params) {
	return axios({
		method: 'post',
		url: Api.teacherUpdate,
		data: params
	})
}
// 学生文件库添加
export function addStudentFiles(params) {
	return axios({
		method: 'post',
		url: Api.studentFilesAdd,
		data: params
	})
}
// 学生账号管理列表
export function listStudentAccount(params) {
	return axios({
		method: 'post',
		url: Api.studentAccountLists,
		data: params
	})
}
//保存学生账号管理
export function addStudentAccount(params) {
	return axios({
		method: 'post',
		url: Api.studentAccountAdd,
		data: params
	})
}
//更新账号管理
export function updateStudentAccount(params) {
	return axios({
		method: 'post',
		url: Api.studentAccountUpdate,
		data: params
	})
}
// 学生添加
export function addStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentAdd,
		data: params
	})
}
// 学生编辑
export function editStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentEdit,
		data: params
	})
}

// 学生个人资料
export function personalViewStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentPersonView,
		data: params
	})
}
// 学生家庭资料
export function familyViewStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentFamilyView,
		data: params
	})
}
// 学生家庭编辑
export function AddStudentFamily(params) {
	return axios({
		method: 'post',
		url: Api.studentFamilyAdd,
		data: params
	})
}
// 学生家庭信息删除
export function DeleteStudentFamily(params) {
	return axios({
		method: 'post',
		url: Api.studentFamilyDelete,
		data: params
	})
}
// 学生教育信息
export function educationViewStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentEducationView,
		data: params
	})
}
// 学生教育信息删除
export function DeleteStudentEducation(params) {
	return axios({
		method: 'post',
		url: Api.StudentEducationDelete,
		data: params
	})
}
// 学生教育编辑
export function AddStudentEducation(params) {
	return axios({
		method: 'post',
		url: Api.studentEducationAdd,
		data: params
	})
}

// 学生标化GPA
export function gradeGpaStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGpa,
		data: params
	})
}
// 学生标化GPA新增
export function AddStudentGradeGpa(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGpaAdd,
		data: params
	})
}
// 学生标化托福信息
export function gradeTofuStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeTofu,
		data: params
	})
}
// 学生标化托福新增
export function AddStudentGradeTofu(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeTofuAdd,
		data: params
	})
}
// 学生标化托福编辑
export function EditStudentGradeTofu(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeTofuEdit,
		data: params
	})
}
// 学生标化托福删除
export function DeleteStudentGradeTofu(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeTofuDelete,
		data: params
	})
}

// 学生标化雅思信息
export function gradeYasiStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeYasi,
		data: params
	})
}
// 学生标化雅思新增
export function AddStudentGradeYasi(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeYasiAdd,
		data: params
	})
}
// 学生标化雅思编辑
export function EditStudentGradeYasi(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeYasiEdit,
		data: params
	})
}
// 学生标化雅思删除
export function DeleteStudentGradeYasi(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeYasiDelete,
		data: params
	})
}

// 学生标化多邻国信息
export function gradeDuolinStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeDuolin,
		data: params
	})
}
// 学生标化多邻国新增
export function AddStudentGradeDuolin(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeDuolinAdd,
		data: params
	})
}
// 学生标化多邻国编辑
export function EditStudentGradeDuolin(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeDuolinEdit,
		data: params
	})
}
// 学生标化多邻国删除
export function DeleteStudentGradeDuolin(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeDuolinDelete,
		data: params
	})
}


// 学生标化SAT信息
export function gradeSatStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSat,
		data: params
	})
}
// 学生标化SAT新增
export function AddStudentGradeSat(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSatAdd,
		data: params
	})
}
// 学生标化SAT编辑
export function EditStudentGradeSat(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSatEdit,
		data: params
	})
}
// 学生标化SAT删除
export function DeleteStudentGradeSat(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSatDelete,
		data: params
	})
}


// 学生标化SAT2信息
export function gradeSat2Students(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSat2,
		data: params
	})
}
// 学生标化SAT2新增
export function AddStudentGradeSat2(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSat2Add,
		data: params
	})
}
// 学生标化SAT2编辑
export function EditStudentGradeSat2(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSat2Edit,
		data: params
	})
}
// 学生标化SAT2删除
export function DeleteStudentGradeSat2(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSat2Delete,
		data: params
	})
}
// 学生标化SAT2科目信息
export function gradeSat2SubjectStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSat2Subject,
		data: params
	})
}
// 学生标化SAT2科目新增
export function AddStudentGradeSat2Subject(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeSat2SubjectAdd,
		data: params
	})
}

// 学生标化Ap信息
export function gradeApStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeAp,
		data: params
	})
}
// 学生标化Ap新增
export function AddStudentGradeAp(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeApAdd,
		data: params
	})
}
// 学生标化Ap编辑
export function EditStudentGradeAp(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeApEdit,
		data: params
	})
}
// 学生标化Ap删除
export function DeleteStudentGradeAp(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeApDelete,
		data: params
	})
}

// 学生标化ALevel信息
export function gradeALevelStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeALevel,
		data: params
	})
}
// 学生标化ALevel新增
export function AddStudentGradeALevel(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeALevelAdd,
		data: params
	})
}
// 学生标化ALevel编辑
export function EditStudentGradeALevel(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeALevelEdit,
		data: params
	})
}
// 学生标化ALevel删除
export function DeleteStudentGradeALevel(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeALevelDelete,
		data: params
	})
}
// 学生标化ALevel科目信息
export function gradeALevelSubjectStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeALevelSubject,
		data: params
	})
}
// 学生标化ALevel科目新增
export function AddStudentGradeALevelSubject(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeALevelSubjectAdd,
		data: params
	})
}


// 学生标化Ib信息
export function gradeIbStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeIb,
		data: params
	})
}
// 学生标化Ib新增
export function AddStudentGradeIb(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeIbAdd,
		data: params
	})
}
// 学生标化Ib编辑
export function EditStudentGradeIb(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeIbEdit,
		data: params
	})
}
// 学生标化Ib删除
export function DeleteStudentGradeIb(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeIbDelete,
		data: params
	})
}

// 学生标化GRE信息
export function gradeGreStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGre,
		data: params
	})
}
// 学生标化GRE新增
export function AddStudentGradeGre(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGreAdd,
		data: params
	})
}
// 学生标化GRE编辑
export function EditStudentGradeGre(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGreEdit,
		data: params
	})
}
// 学生标化GRE删除
export function DeleteStudentGradeGre(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGreDelete,
		data: params
	})
}

// 学生标化GMAT信息
export function gradeGmatStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGmat,
		data: params
	})
}
// 学生标化GMAT新增
export function AddStudentGradeGmat(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGmatAdd,
		data: params
	})
}
// 学生标化GMAT编辑
export function EditStudentGradeGmat(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGmatEdit,
		data: params
	})
}
// 学生标化GMAT删除
export function DeleteStudentGradeGmat(params) {
	return axios({
		method: 'post',
		url: Api.studentGradeGmatDelete,
		data: params
	})
}

// 学生标化奖项信息
export function awardStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentAward,
		data: params
	})
}
// 学生标化奖项新增
export function AddStudentAward(params) {
	return axios({
		method: 'post',
		url: Api.studentAwardAdd,
		data: params
	})
}
// 学生标化奖项编辑
export function EditStudentAward(params) {
	return axios({
		method: 'post',
		url: Api.studentAwardEdit,
		data: params
	})
}
// 学生标化奖项删除
export function DeleteStudentAward(params) {
	return axios({
		method: 'post',
		url: Api.studentAwardDelete,
		data: params
	})
}


// 学生标化活动信息
export function activityStudents(params) {
	return axios({
		method: 'post',
		url: Api.studentActivity,
		data: params
	})
}
// 学生标化活动新增
export function AddStudentActivity(params) {
	return axios({
		method: 'post',
		url: Api.studentActivityAdd,
		data: params
	})
}
// 学生标化活动编辑
export function EditStudentActivity(params) {
	return axios({
		method: 'post',
		url: Api.studentActivityEdit,
		data: params
	})
}
// 学生标化活动删除
export function DeleteStudentActivity(params) {
	return axios({
		method: 'post',
		url: Api.studentActivityDelete,
		data: params
	})
}